import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-4">Lingkar Bina Muda adalah komunitas pemberdayaan anak muda dibawah naungan Yayasan Al-Kautsar</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://www.alkautsar.org/">Yayasan Al-Kautsar Pelita Insani</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://www.facebook.com/kabimaofficial">Facebook</a>
          </li>
          <li>
            <a href="https://www.instagram.com/kabima_official/">Instagram</a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCgPG2FlQx9npYEc21kkt7rw">Youtube</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
