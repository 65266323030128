import React, { useState, useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from "gatsby";

const Header = () => {
  const [isClient, setIsClient] = useState(false);
  
  useEffect(() => {
    setIsClient(true);
  }, []);

  const isHomePage = isClient && window.location.pathname === '/';

  const scrollToSection = (sectionId) => {
    if (!isHomePage) return;
    
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const NavLinks = () => {
    if (!isClient) {
      // Server-side rendering - return regular links
      return (
        <>
          <Link className="px-4" to="/#layanan">Program</Link>
          <Link className="px-4" to="/#testimoni">Testimoni</Link>
          <Link className="px-4" to="/#tanyaJawab">Tanya Jawab</Link>
        </>
      );
    }

    // Client-side rendering
    return isHomePage ? (
      <>
        <AnchorLink className="px-4" href="#layanan" onClick={() => scrollToSection('layanan')}>
          Program
        </AnchorLink>
        <AnchorLink className="px-4" href="#testimoni" onClick={() => scrollToSection('testimoni')}>
          Testimoni
        </AnchorLink>
        <AnchorLink className="px-4" href="#tanyaJawab" onClick={() => scrollToSection('tanyaJawab')}>
          Tanya Jawab
        </AnchorLink>
      </>
    ) : (
      <>
        <Link className="px-4" to="/#layanan">Program</Link>
        <Link className="px-4" to="/#testimoni">Testimoni</Link>
        <Link className="px-4" to="/#tanyaJawab">Tanya Jawab</Link>
      </>
    );
  };

  return (
    <header className="sticky top-0 bg-white shadow z-50">
      <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
        <div className="flex items-center text-2xl">
          <Link to="/" className="px-4">
            <img 
              src="../../../kabimaLogoNew.png" 
              alt="Kabima Logo"
              style={{ height: '80px', width: '60px' }}
            />
          </Link>
        </div>
        
        <nav className="flex items-center mt-4 sm:mt-0">
          <NavLinks />
          <Link className="px-4" to="/ebook">
            eBook
          </Link>
        </nav>
        <div />
      </div>
    </header>
  );
};

export default Header;